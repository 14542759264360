import React from 'react';

function Camping() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#78B159"
          d="M50 69.945C9.55 69.945 0 82.9 0 88.889 0 94.878 4.975 100 11.111 100H88.89C95.025 100 100 94.859 100 88.889c0-5.97-9.55-18.944-50-18.944z"
        />
        <path
          fill="#662113"
          d="M80.011 78.195c0 4.252-3.45 5.777-7.703 5.777-4.252 0-7.702-1.522-7.702-5.777l1.925-17.331c0-4.253 1.522-3.853 5.777-3.853 4.253 0 5.778-.403 5.778 3.853l1.925 17.33z"
        />
        <path
          fill="#5C913B"
          d="M96.856 54.77c-12.75-11.487-17.914-27.934-24.547-27.934-6.634 0-11.798 16.447-24.545 27.933-5.358 4.828-2.805 7.62 4.539 7.062 6.547-.498 12.67-2.059 20.006-2.059s13.46 1.564 20.005 2.059c7.345.558 9.897-2.237 4.542-7.062z"
        />
        <path
          fill="#3E721D"
          d="M92.714 50.217c-9.797-9.447-15.303-22.98-20.405-22.98-5.1 0-10.606 13.533-20.403 22.98-7.786 7.508 10.203 9.191 20.403 0 10.197 9.191 28.188 7.508 20.405 0z"
        />
        <path
          fill="#5C913B"
          d="M92.714 42.564c-9.797-8.828-15.303-21.47-20.405-21.47-5.1 0-10.606 12.642-20.403 21.47-7.786 7.014 10.203 8.586 20.403 0 10.197 8.586 28.188 7.011 20.405 0z"
        />
        <path
          fill="#3E721D"
          d="M88.239 36.294c-7.647-6.891-11.947-16.76-15.93-16.76-3.981 0-8.281 9.869-15.931 16.76-6.078 5.475 7.964 6.706 15.93 0 7.964 6.703 22.009 5.475 15.93 0z"
        />
        <path
          fill="#5C913B"
          d="M88.239 30.558c-7.647-6.891-11.947-16.76-15.93-16.76-3.981 0-8.281 9.869-15.931 16.76-6.078 5.475 7.964 6.706 15.93 0 7.964 6.706 22.009 5.478 15.93 0z"
        />
        <path
          fill="#3E721D"
          d="M85.164 25.466c-6.173-5.56-9.642-13.525-12.856-13.525-3.214 0-6.683 7.964-12.856 13.525-4.905 4.42 6.428 5.412 12.856 0 6.428 5.412 17.764 4.42 12.856 0z"
        />
        <path
          fill="#5C913B"
          d="M85.164 21.15C78.99 15.589 75.522 7.625 72.308 7.625c-3.214 0-6.683 7.964-12.856 13.525-4.905 4.42 6.428 5.411 12.856 0 6.428 5.411 17.764 4.42 12.856 0z"
        />
        <path
          fill="#3E721D"
          d="M82.092 14.794c-4.695-4.23-7.336-10.291-9.784-10.291-2.444 0-5.086 6.06-9.783 10.291-3.733 3.364 4.892 4.117 9.783 0 4.892 4.12 13.517 3.364 9.784 0z"
        />
        <path
          fill="#5C913B"
          d="M82.092 11.917c-4.695-4.23-7.336-10.292-9.784-10.292-2.444 0-5.086 6.061-9.783 10.292-3.733 3.364 4.892 4.116 9.783 0 4.892 4.116 13.517 3.364 9.784 0z"
        />
        <path
          fill="#F4900C"
          d="M41.947 91.667a2.784 2.784 0 01-2.622-1.85l-13.922-38.98a2.678 2.678 0 011.348-3.331 2.75 2.75 0 011.277-.284h32.664c1.114 0 2.122.611 2.56 1.636l16.706 38.953a2.76 2.76 0 01-2.563 3.856H41.947z"
        />
        <path
          fill="#FFCC4D"
          d="M11.32 91.667a2.792 2.792 0 01-2.559-3.884l16.717-39.005a2.782 2.782 0 015.12 0L47.313 87.78a2.794 2.794 0 01-2.561 3.886H11.319z"
        />
        <path
          fill="#292F33"
          d="M28.028 53.989l-5.572 37.678h11.138l-5.566-37.678z"
        />
        <path
          fill="#FFAC33"
          d="M39.242 88.575l1.244 3.092h-6.892l5.648-3.092zm-22.43 0l-1.245 3.092h6.889l-5.645-3.092z"
        />
        <path
          fill="#FFE8B6"
          d="M33.595 91.667s5.064-1.292 7.539-3.578l-13.106-34.1 5.567 37.678zm-11.14 0s-5.063-1.292-7.538-3.578l13.108-34.103-5.57 37.68z"
        />
        <path
          fill="#E1E8ED"
          d="M37.847 15.625c-.05 0-.094.017-.144.017a6.944 6.944 0 00-6.8-8.353 6.917 6.917 0 00-5.861 3.253 6.888 6.888 0 00-2.473-.473 6.92 6.92 0 00-6.858 6.084 6.726 6.726 0 00-2.517-.528 6.945 6.945 0 000 13.889h24.653a6.945 6.945 0 100-13.889z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H100V100H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Camping;
