import React, { useState } from 'react';
import data from './donation-data';
import DonationCard from './donation-card';
import DonationModal from './donation-modal';
import Button from '../layout/button';

const DonationDescript = () => {
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState(data[0]);

  const openModalWithCategory = selectedCategory => {
    setCategory(selectedCategory);
    setModal(true);
  };

  return (
    <section>
      <div className="container">
        <div
          id="adomanyozas"
          className="px-4 py-8 lg:py-16 md:flex md:flex-row-reverse"
        >
          <div className="md:w-1/2 flex flex-wrap md:items-center lg:justify-center mb-10">
            {data.map(donation => (
              <DonationCard
                donation={donation}
                key={donation.description}
                handleModal={openModalWithCategory}
              />
            ))}
          </div>
          <DonationModal
            modal={modal}
            setModal={setModal}
            category={category}
          />
          <div className="md:w-1/2 max-w-md mx-auto my-auto">
            <h4 className="text-2xl italic font-medium mb-6">Adományozz</h4>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Az összes programot, amit a gyerekeknek viszünk díjmentesen
              biztosítjuk, ezt adományozók segítségének és a kettős profilunknak
              köszönhetően tudjuk megoldani. Természetesen bármekkora összeg
              felajánlásának örülünk, hisz nincs kis adomány, csak fel nem
              ajánlott. Mire elég egy-egy utalás?
            </p>
          </div>
        </div>
        <div id="onkentes" className="px-4 py-8 lg:py-16 md:flex">
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <img
              alt="Talk AS"
              src="/icons/onkentes.svg"
              width="414"
              className="mx-auto m-5 p-5 lg:p-0"
            />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto my-auto">
            <h4 className="text-2xl italic font-medium mb-6">
              Legyél önkéntes
            </h4>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Keresünk lelkes önkénteseket, akik segítik az Alapítványunkat a
              célok elérésében. Önkéntesek fő feladata a kitelepüléseken
              segédkezés. Egy-egy kitelepülés három fő részből épül fel:
            </p>
            <ul className="list-disc ml-4 mb-10 text-base text-left leading-loose px-1">
              <li>szoba berendezése az adott játékmenet szerint</li>
              <li>játékok levezetése</li>
              <li>összepakolás</li>
            </ul>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Az önkénteseink minden szakaszt kipróbálhatnak, hogy
              megtalálhassák a számukra leginkább megfelelő munkakört.
            </p>
            <Button variant="primary" to="/kapcsolat">
              Lépj kapcsolatba velünk!
            </Button>
          </div>
        </div>
        <div
          id="szakertelem"
          className="px-4 py-8 lg:py-16 md:flex md:flex-row-reverse"
        >
          <div className="md:w-1/2 md:flex md:items-center md:justify-center mb-10">
            <div className="card bg-white shadow-lg md:mx-2 rounded-lg h-64 w-56 mx-auto md:mt-20">
              <img
                alt="Eszközök"
                src="/icons/devices.svg"
                width="110"
                className="mx-auto"
              />
              <p className="text-center p-3 w-40 mx-auto">
                Social media felületek kezelése
              </p>
            </div>
            <div className="card bg-white shadow-lg lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto">
              <img
                alt="Kampány"
                src="/icons/kampany.svg"
                width="110"
                className="mx-auto"
              />
              <p className="text-center p-3 w-40 mx-auto">
                Kampányok felépítése
              </p>
            </div>
          </div>
          <div className="md:w-1/2 max-w-md mx-auto my-auto">
            <h4 className="text-2xl italic font-medium mb-6">
              Támogass szakértelemmel
            </h4>
            <p className="mb-10 text-base text-left leading-loose px-1">
              Nem feltétlen kell, hogy profi szövegíró legyél vagy
              kampánymenedzser, elég ha lelkes vagy és érdekel a téma. Nem kell
              utazni, lehet otthonról végezni a feladatok nagy részét.
              Természetesen szeretnénk Téged személyesen is megismerni, de a
              feladatok nagyobb részét otthonról / kávézóból / ahol Te szeretnéd
              el tudod végezni.
            </p>
            <Button variant="primary" to="/kapcsolat">
              Lépj kapcsolatba velünk!
            </Button>
          </div>
        </div>
        <div
          id="mese"
          className="px-4 py-8 lg:py-16 md:flex md:items-center md:justify-center"
        >
          <div className="md:w-1/2 md:flex md:items-center md:justify-center">
            <img
              alt="Talk AS"
              src="/icons/talkas.svg"
              width="414"
              className="mx-auto m-5 p-5 lg:p-0"
            />
          </div>
          <div className="md:w-1/2 max-w-md mx-auto">
            <h4 className="text-2xl italic font-medium mb-6">Mesélj rólunk</h4>
            <p className="mb-10 text-base text-left leading-loose px-1">
              A gyerekek számára díjmentes minden programunk, aminek nagyobb
              részét abból tudjuk finanszírozni, hogy cégekkel dolgozunk együtt.
              Ha unod már a sablonos csapatépítő programokat és valami
              izgalmasra vágynál, akkor küldd el a HR-esnek az oldalunkat és
              mesélj neki arról, hogy miért lenne jó Velünk dolgozni.
            </p>
            <Button variant="primary" to="/kapcsolat">
              Lépj kapcsolatba velünk!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationDescript;
