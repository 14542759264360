import React from 'react';
// import Coins from '../icons/coins';

const DonationCard = ({ donation, handleModal }) => {
  const { img, width, description, amount } = donation;
  return (
    <div
      onClick={() => handleModal(donation)}
      className="bg-white shadow-lg lg:m-2 rounded-xl h-56 md:h-64 w-40 mt-5 mx-auto cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
    >
      {/* <Coins /> */}
      <img
        alt={img}
        src={`/icons/${img}.svg`}
        width={width}
        className="mx-auto mt-10"
      />
      <p className="text-center text-base font-semibold">{amount}</p>
      <p className="text-center text-sm italic pl-2 pr-2 w-40 pt-3 mx-auto">
        {description}
      </p>
    </div>
  );
};

export default DonationCard;
