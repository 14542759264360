export default [
  {
    img: 'children',
    width: '72',
    amount: '2 000 Ft',
    total: 2000,
    description: 'Egy kiskamasz egy órás játékának teljes költségét fedezi.'
  },
  {
    img: 'users',
    width: '72',
    amount: '8 750 Ft',
    total: 8750,
    description: 'Egy csapat egy órás játékának teljes költségét fedezi.'
  },
  {
    img: 'users2',
    width: '72',
    amount: '17 750 Ft',
    total: 17750,

    description: 'Kettő csapat egy órás játékának teljes költségét fedezi.'
  },
  {
    img: 'halfsun',
    width: '54',
    amount: '35 000 Ft',
    total: 35000,
    description: 'Egy fél napos kitelepülésünk teljes költségét fedezi.'
  },
  {
    img: 'sun',
    width: '54',
    amount: '74 000 Ft',
    total: 74000,
    description: 'Egy egynapos kitelepülésünk teljes költségét fedezi.'
  },
  {
    img: 'coins',
    width: '72',
    amount: 'Egyéb összeg',
    total: 0,
    description: 'Minden felajánlott összeg számít'
  }
];
