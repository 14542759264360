import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import axios from 'axios';
import { baseClasses, variants } from '../layout/button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10'
  }
};

const DonationModal = ({ modal, setModal, category }) => {
  const [barionLink, setBarionLink] = useState('');

  function closeModal() {
    setModal(false);
  }

  const initialValues = {
    amount: category.total,
    name: '',
    email: '',
    phone: '',
    message: '',
    agreeDonation: '',
    newsletter: ''
  };

  const onSubmit = ({ name, email, phone, amount, message }) => {
    axios
      .post('/api/pay', { name, phone, email, amount, message })
      .then(({ data }) => {
        setBarionLink(data.GatewayUrl);
      });
  };

  const CheckOut = Yup.object().shape({
    amount: Yup.number()
      .min(500, 'Minimum összege 500Ft')
      .required('Kötelező kitölteni!'),
    name: Yup.string()
      .min(2, 'Túl rövid!')
      .max(50, 'Túl hosszú')
      .required('Kötelező kitölteni!'),
    email: Yup.string()
      .min(5, 'Túl rövid!')
      .max(50, 'Túl hosszú')
      .required('Kötelező kitölteni!'),
    phone: Yup.number().required('Kötelező kitölteni!'),
    message: Yup.string()
      .min(2, 'Túl rövid!')
      .max(50, 'Túl hosszú'),
    agreeDonation: Yup.string().required('Kötelező kitölteni!'),
    newsletter: ''
  });

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      onRequestClose={closeModal}
      center
      focusTrapped={false}
      showCloseIcon={false}
      style={customStyles}
      classNames={{
        overlay: 'modal-overlay',
        modal: 'modal-ten'
      }}
    >
      <button
        className="fixed top-0 right-0 m-4 lg:hidden lg:m-10"
        onClick={closeModal}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="mx-auto z-10"
        >
          <>
            <path d="M18 6L6 18" />
            <path d="M6 6L18 18" />
          </>
        </svg>
      </button>
      {barionLink ? (
        <div className="flex flex-col items-center mt-10 mb-10">
          <p className="mx-auto text-center mb-3">
            A kényelmes és biztonságos online fizetést a Barion Payment Zrt.
            biztosítja.
          </p>
          <p className="mx-auto text-center mb-3">
            MNB engedély száma: H-EN-I-1064/2013 Bankkártya adatai
            weboldalunkhoz nem jutnak el
          </p>
          <p className="mx-auto text-center mb-3">
            Barion Pixel Alap Verziója csalásmegelőzési célból beépítésre került
            az elfogadóhelybe
          </p>
          <img alt="Barion" src="/logo.png" className="inline" />
          <a
            href={barionLink}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-8 text-center tracking-wider font-bold uppercase py-4 px-10 border-2 border-primary rounded-full inline-block m-4 min-w-64 mx-auto hover:shadow-xl transition duration-200 ease-in-out transform hover:-translate-y-1 bg-primary text-white hover:bg-primary"
          >
            Tovább a Barion oldalára{' '}
          </a>{' '}
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={CheckOut}
        >
          <Form className="m-5">
            <h2 className="text-center">Adomány</h2>
            <div className="max-w-lg md:px-5 mx-auto">
              <div className="m-5">
                <label htmlFor="name" className="text-sm font-semibold mb-2">
                  Név
                </label>
                <Field
                  name="name"
                  id="name"
                  type="text"
                  // placeholder="Gipsz Jakab"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className=" text-red-700 p-1"
                />
              </div>
              <div className="m-5 ">
                <label htmlFor="email" className="text-sm font-semibold mb-2">
                  E-mail cím
                </label>
                <Field
                  id="email"
                  name="email"
                  // placeholder="jakab@gamil.com"
                  type="text"
                />

                <ErrorMessage
                  name="email"
                  component="div"
                  className=" text-red-700 p-1"
                />
              </div>
              <div className="m-5">
                <label htmlFor="phone" className="text-sm font-semibold mb-2">
                  Telefonszám
                </label>
                <Field
                  name="phone"
                  id="phone"
                  // placeholder="+36 30 123 4567"
                  type="text"
                />

                <ErrorMessage
                  name="phone"
                  component="div"
                  className=" text-red-700 p-1"
                />
              </div>
            </div>

            <div className="max-w-lg md:px-5 mx-auto">
              <div className="m-5">
                <label htmlFor="amount" className="text-sm font-semibold mb-2">
                  Összeg
                </label>
                <Field
                  name="amount"
                  id="amount"
                  type="text"
                  // placeholder="Gipsz"
                />

                <ErrorMessage
                  name="amount"
                  component="div"
                  className=" text-red-700 p-1"
                />
              </div>
            </div>

            <div className="max-w-lg md:px-5 mx-auto">
              <div className="m-5">
                <label htmlFor="name" className="text-sm font-semibold mb-2">
                  Üzenet
                </label>
                <Field
                  name="message"
                  id="messager"
                  // placeholder="Ide írja üzenetét"
                  className="border border-gray-500 rounded-md p-2 w-full"
                  rows="4"
                  component="textarea"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className=" text-red-700 p-1"
                />
              </div>
            </div>
            <div className="m-5 max-w-md mx-auto px-1">
              <Field type="checkbox" name="agreeDonation" />
              <ErrorMessage
                name="agreeDonation"
                component="div"
                className=" text-red-700 p-1"
              />
              <p className="inline ml-2 ">
                Elolvastam az{' '}
                <a
                  href="/Adomanyozas.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pt-4 hover:underline text-orange-500"
                >
                  Adományozási feltételeket
                </a>{' '}
                és az{' '}
                <a
                  href="/FsA_Adatvédelmi_nyilatkozat.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pt-4 hover:underline text-orange-500"
                >
                  Adatvédelmi tájékoztatót
                </a>
                , és hozzájárulok személyes adataim kezeléséhez.
              </p>
            </div>
            <div className="m-5 max-w-md mx-auto px-1">
              <Field type="checkbox" name="newsletter" />
              <ErrorMessage name="newsletter" component="div" />

              <p className="inline ml-2">Feliratkozom a hírlevélre.</p>
            </div>
            <div className="m-5 text-center">
              <button
                type="submit"
                className={clsx(baseClasses, variants.primary)}
              >
                Tovább a fizetéshez
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default DonationModal;
