import React from 'react';
import Card from '../layout/card';
import roomData from './room-data';

const EscapeRoom = () => {
  return (
    <div className="container mt-40 mb-20">
      <h2 className="uppercase text-center text-4xl md:text-5xl mb-16">
        A szabadulószoba
      </h2>
      <div className="md:flex">
        <div className="md:w-1/2 p-5 md:mt-20 lg:mt-10">
          <p className="max-w-lg lg:px-20 mx-auto">
            A Fortély-sátor Alapítvány évek óta foglalkozik szabadulószobákkal,
            ami egy <b>innovatív</b> és hasznos{' '}
            <b>
              pedagógiai módszer fiatalok tanulási motivációjának és
              önbizalmának megteremtésében
            </b>
            . A játék során 4-6 fős csoportoknak kell együtt dolgozni olyan
            helyzetben, ami kimozdítja őket a komfortzónájukból.
          </p>
          <p className="max-w-lg lg:px-20 mt-5 mx-auto mb-10">
            A különböző kreatív problémamegoldásra épülő feladatok fejlesztik a
            logikai és kritikai gondolkodást, a kooperációs és problémamegoldó
            képességet, és ami a legfontosabb:{' '}
            <b>sikerélményt ad a játékosoknak</b>, függetlenül attól, hogy
            sikerül-e kiszabadulniuk.
          </p>
        </div>
        <div className="md:w-1/2 lg:flex lg:flex-row justify-center">
          <div className="flex flex-col lg:mt-20">
            {roomData.slice(0, 2).map(content => (
              <Card key={content.description} content={content} />
            ))}
          </div>
          <div className="flex flex-col">
            {roomData.slice(2, 4).map(content => (
              <Card key={content.description} content={content} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EscapeRoom;
