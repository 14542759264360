import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const HowWork = () => {
  const images = useStaticQuery(graphql`
    query {
      game: file(relativePath: { regex: "/method/game1.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 523) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="my-10 xl:my-20 container mx-auto">
      <h2 className="uppercase text-center pt-10 md:mb-10">
        HOGYAN ÉPÜL FEL EGY JÁTÉK?
      </h2>
      <div className="w-full md:flex md:flex-row justify-between">
        <div>
          <div className="max-w-md my-12 m-5">
            <h3>HELYSZÍN</h3>
            <p className="mt-3">
              A klasszikus szabadulószobákat alakítottuk át teljesen mobillá,
              ezzel lehetővé téve, hogy bárhová el tudjuk vinni a játék
              élményét.{' '}
            </p>
          </div>
          <div className="max-w-md my-12 m-5">
            <h3>LÉTSZÁM</h3>
            <p className="mt-3">
              Egy szobában 2-5 fő közötti csapatok tudnak játszani, arra
              természetesen van lehetőség, hogy egyszerre több játék működjön
              párhuzamosan.
            </p>
          </div>
          <div className="max-w-md my-12 m-5">
            <h3>ESZKÖZÖK</h3>
            <p className="mt-3">
              Minden eszközt mi viszünk a helyszínre, az alap berendezések
              elhelyezéséről előzetes egyeztetés során döntünk.
            </p>
          </div>
          <div className="max-w-md mt-10 m-5">
            <h3>AMIRE SZÜKSÉGÜNK VAN</h3>
            <div className="md:flex md:flex-row">
              <div className="bg-white shadow-xl lg:m-2 rounded-xl h-56 w-40 mt-5 mx-auto">
                <img
                  alt="Ajtó"
                  src="/icons/door.svg"
                  width={64}
                  className="mx-auto mt-10"
                />
                <p className="text-center text-sm italic pl-2 pr-2 w-40 pt-5 mx-auto">
                  egy 20-25 négyzetméteres szobára, amin van zárható ajtó
                </p>
              </div>
              <div className="bg-white shadow-xl lg:m-2 rounded-xl h-56 w-40 mt-5 mx-auto">
                <img
                  alt="Ajtó"
                  src="/icons/assistant.svg"
                  width={64}
                  className="mx-auto mt-10"
                />
                <p className="text-center text-sm italic pl-3 pr-3 w-40 pt-5 mx-auto">
                  a közelben egy másik, kisebb térre, innen irányítjuk a játékot
                </p>
              </div>
              <div className="bg-white shadow-xl lg:m-2 rounded-xl h-56 w-40 mt-5 mx-auto">
                <img
                  alt="Ajtó"
                  src="/icons/flash.svg"
                  width={64}
                  className="mx-auto mt-10"
                />
                <p className="text-center text-sm italic pl-2 pr-2 w-40 pt-5 mx-auto">
                  jó, ha mind a két szobában van áram, vagy tudunk oda juttatni
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-t md:w-1/2 pt-10 md:px-5">
          <Img
            className="rounded-xl mx-auto md:ml-5"
            alt="Game"
            fluid={images.game.childImageSharp.fluid}
          />
        </div>
      </div>
    </div>
  );
};

export default HowWork;
