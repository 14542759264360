import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Button from '../layout/button';

const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          hero: file(relativePath: { regex: "/hero_bg.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ hero }) => (
        <BackgroundImage
          Tag="section"
          id="hero h-screen"
          fluid={hero.childImageSharp.fluid}
        >
          <div className="container mx-auto h-full mt-20 md:pb-64">
            <div className="flex flex-wrap text-center pb-20">
              <div className="w-full p-5 mt-20">
                <h1 className="font-bold  text-4xl leading-normal max-w-lg mx-auto pb-10 ">
                  Sikerélményt viszünk a fiatalok életébe szociális helyzettől
                  függetlenül.
                </h1>
              </div>
              <div className="flex flex-col md:flex-row mx-auto md:pb-20">
                <Button to="/rolunk" variant="secondary" className="md:mr-20">
                  Tudj meg többet!
                </Button>
                <Button to="/tamogatas">Támogass minket!</Button>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Hero;
