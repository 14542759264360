import React from 'react';

function Face() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M2247 5095c-795-96-1494-558-1908-1260-172-292-292-660-329-1007-15-144-12-458 5-595 50-393 183-753 401-1088 190-293 477-573 784-767 241-151 558-281 815-332 44-9 90-18 103-21l22-5v2000h-620v710h618l4 363c4 354 5 364 31 461 84 314 286 522 588 606 134 37 273 45 531 31 128-7 250-15 271-18l37-5v-628h-218c-120 0-245-5-277-11-104-19-171-67-209-148-20-43-21-65-24-348l-3-303h350c326 0 351-1 351-17 0-22-79-640-85-670l-5-23h-610V10h23c36 0 195 30 297 55 231 58 523 182 720 305 221 137 507 391 652 575 283 362 452 742 529 1189 27 154 37 511 19 683-76 750-479 1422-1110 1855-338 231-724 375-1138 423-154 18-464 18-615 0z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default Face;
