// import React from 'react';
// import Bt from '../../icons/logos/bt';
// import Bur from '../../icons/logos/bur';
// import Dia from '../../icons/logos/dia';
// import Kner from '../../icons/logos/kner';
// import Kt from '../../icons/logos/kt';
// import Magnet from '../../icons/logos/magnet';
// import Mm from '../../icons/logos/mm';
// import Pwc from '../../icons/logos/pwc';
// import Szt from '../../icons/logos/szt';
// import Tedx from '../../icons/logos/tedx';

export default [
  {
    img: 'pwc',
    width: '136',
    name: 'PwC Magyarország',
    description:
      'Többször elindultunk és támogatásban részesültünk a Másokról szól pályázaton, amely hozzájárult több szobánk elkészítéséhez is. Emellett készítettünk számukra HR-es célokra saját felhasználású szobákat, amiket azóta is sikeresen alkalmaznak.'
  },
  {
    img: 'magnet',
    width: '127',
    name: 'MagNet Bank',
    description:
      'Alapítványunk részt vesz a Segítő bankkártya programban, ahol az ügyfelek kártyás vásárlása után kapunk támogatást. Továbbá a Bank ügyfelei egyéni adományt is küldhetnek a szervezetünknek. https://www.magnetbank.hu/'
  },
  {
    img: 'bt',
    width: '140',
    name: 'Bátor Tábor',
    description:
      'Családi napon és cimbora találkozón vettünk részt saját szabadulószobával. Az éves Bátor Tábor Jótékonysági Golfnapra egyedi szabadulószobás kitelepülést ajánlottunk fel.'
  },
  {
    img: 'tedx',
    width: '212',
    name: 'TEDxYouth @ Budapest',
    description:
      'A konferencián két egymást követő évben is workshopot tartottunk, ami során a résztvevők játszhattak a játékunkkal.'
  },
  {
    img: 'dia',
    width: '151',
    name: 'Dia',
    description:
      'A DIA által szervezett Youth2Youth társadalmi innovációs versenyen született meg az Alapítvány ötlete. Az induló időszakban a DeMo projektjük részeként működtünk, majd utána önállósodtunk. Azóta is több közös programunk volt, például az első Kamaszkonferenciájukon is kipróbálhatták a játékunkat a résztvevők.'
  },
  {
    img: 'szt',
    width: '82',
    name: 'Szabad Terek',
    description:
      'Országos szerveződés, ami összehoz különböző civil szervezeteket, így megkönnyítve és elősegítve az együttműködést. A szervezetnek köszönhetően tudtunk részt venni a szegedi Klímanapok programsorozatban.'
  },
  {
    img: 'mm',
    width: '100',
    name: 'Motiváció Műhely',
    description:
      'A Motiváció Műhely által támogatott gyerekek inspiráltak minket az ötletünk megvalósításához. Azóta számos alkalommal vittük el a tanodába a játékot, illetve a Szabad Terek civil szerveződésben is közösen dolgozunk.'
  },
  {
    img: 'kner',
    width: '118',
    name: 'Kner Imre Disputakör',
    description:
      'Több alkalommal jártunk a szobáinkkal a gyomaendrődi szervezetnél, ahol egész napos programokat szolgáltattunk diákoknak.'
  },
  {
    img: 'kt',
    width: '120',
    name: 'Kompánia Tanoda',
    description:
      'Számos alkalommal vittük el sikeresen különböző játékainkat a tanodába.'
  },
  {
    img: 'burattino',
    width: '100',
    name: 'Burattino Iskola',
    description:
      'Több szobánkat is sikeresen teszteltük náluk, a célcsoportos teszteléseink elsődleges helyszíne.'
  },
  {
    img: 'appy',
    width: '100',
    name: 'Appy',
    description:
      'Az Appy támogatásának köszönhetően profi irodai környezetben dolgozhatunk, továbbá szakmai tanácsadással is segítenek minket.'
  },
  {
    img: 'unicredit',
    width: '200',
    name: 'UniCredit Bank',
    description:
      'A Bank Gift Matching programján keresztül támogatást kaptunk, aminek köszönhetően számos új játékot tudunk elkészíteni és üzemeltetni.'
  }
];
