import React from 'react';

const Partner = ({ partners }) => {
  const { img, width, name, description } = partners;

  return (
    <div className="text-center rounded-lg bg-white m-3 mb-8 p-5 md:w-1/3 lg:w-1/4 xl:w-1/5 shadow-2xl">
      <div className="h-32 flex items-center">
        <img
          alt={img}
          src={`icons/logos/${img}.svg`}
          width={width}
          className="mx-auto"
        />
      </div>
      <h3 className="mb-4 uppercase">{name}</h3>
      <p className="text-sm">{description}</p>
    </div>
  );
};

export default Partner;
