import React from 'react';

const Card = ({ content }) => {
  const { img, width, description } = content;
  return (
    <div className="card bg-white shadow-lg lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto">
      <img
        alt={img}
        src={`icons/${img}.svg`}
        width={width}
        className="mx-auto mt-10 "
      />
      <p className="text-center p-3 w-40 mx-auto">{description}</p>
    </div>
  );
};

export default Card;
