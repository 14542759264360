import React from 'react';

const preference = () => {
  return (
    <div className="bg-bgfirst">
      <h2 className="uppercase text-center pt-10">A módszer előnyei</h2>
      <div className="container flex flex-wrap md:flex-row justify-center pb-10 lg:px-40">
        <div className="m-5 w-2/4 md:w-1/4 text-center mx-auto">
          <img
            alt="Kalap"
            src="/icons/cap.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Fejlesztő</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/4 text-center mx-auto">
          <img
            alt="Puzzle"
            src="/icons/puzzle.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Játékos</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/4 text-center mx-auto">
          <img
            alt="Photos"
            src="/icons/photos.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Tematizálható</p>
        </div>
        <div className="m-5 w-2/4 md:w-1/4 text-center mx-auto">
          <img
            alt="Mozgó"
            src="/icons/transit.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-sm font-semibold">Mobilizálható</p>
        </div>
      </div>
    </div>
  );
};

export default preference;
