import React from 'react';

function Linked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 30 30"
    >
      <path d="M5.879 0C2.646 0 0 2.646 0 5.879v18.243C0 27.355 2.646 30 5.879 30h18.243C27.355 30 30 27.355 30 24.122V5.88C30 2.646 27.355 0 24.122 0H5.88zm1.479 4.95c1.55 0 2.504 1.018 2.534 2.356 0 1.308-.984 2.354-2.564 2.354h-.03c-1.52 0-2.503-1.046-2.503-2.354 0-1.338 1.013-2.355 2.562-2.355zm13.358 6.253c2.98 0 5.216 1.948 5.216 6.135v7.817h-4.53v-7.293c0-1.832-.657-3.082-2.296-3.082-1.252 0-1.998.842-2.325 1.656-.12.292-.15.698-.15 1.106v7.613h-4.53s.06-12.354 0-13.633h4.531v1.93c.602-.928 1.68-2.25 4.084-2.25zm-15.654.32h4.53v13.632h-4.53V11.523z" />
    </svg>
  );
}

export default Linked;
