import React from 'react';
import ScrollTo from '../layout/scroll-to';

const DonationHero = () => {
  return (
    <div className="bg-bgfirst mt-20 pt-10 pb-20">
      <h2 className="uppercase text-center">Hogyan tudsz minket támogatni?</h2>
      <div className="container flex flex-wrap justify-center pt-10">
        <ScrollTo
          to="adomanyozas"
          className="lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
        >
          <img
            alt="Adományozás"
            src="/icons/donate.svg"
            width="100"
            className="mx-auto mt-10 "
          />
          <p className="text-center p-3 w-40 mx-auto">Adományozz</p>
        </ScrollTo>
        <ScrollTo
          to="onkentes"
          className="lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
        >
          <img
            alt="Önkéntes"
            src="/icons/debt.svg"
            width="100"
            className="mx-auto mt-10 "
          />
          <p className="text-center p-3 w-40 mx-auto">Legyél önkéntes</p>
        </ScrollTo>
        <ScrollTo
          to="szakertelem"
          className="lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
        >
          <img
            alt="Szakértelem"
            src="/icons/briefcase.svg"
            width="100"
            className="mx-auto mt-10 "
          />
          <p className="text-center p-3 w-40 mx-auto">
            Támogass szakértelemmel
          </p>
        </ScrollTo>

        <ScrollTo
          to="mese"
          className="lg:m-2 rounded-lg h-64 w-56 mt-5 mx-auto cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
        >
          <img
            alt="Mesélj"
            src="/icons/reading.svg"
            width="100"
            className="mx-auto mt-10 "
          />
          <p className="text-center p-3 w-40 mx-auto">Mesélj rólunk</p>
        </ScrollTo>
      </div>
    </div>
  );
};

export default DonationHero;
