import React from 'react';

function Key() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="121"
      fill="none"
      viewBox="0 0 80 81"
    >
      <path
        fill="#FFA000"
        d="M50 69.213l-6.667 6.667h-6.666L30 69.213V35.88h20v13.333l-3.333 3.333L50 55.88v3.333l-3.333 3.333L50 65.88v3.333z"
      />
      <path
        fill="#FFA000"
        d="M63.333 13.88c-.833-3-3.333-5.167-6.166-6-4-.834-10.167-2-17.167-2-7 0-13.167 1.166-17.167 2-2.833.833-5.333 3-6.166 6C15.833 16.713 15 20.713 15 25.046c0 4.334.833 8.334 1.667 11.167.833 3 3.166 5.167 6.166 5.833 4 1 10.167 2.167 17.167 2.167 7 0 13.167-1.167 17.167-2 3-.667 5.333-3 6.166-5.833C64.167 33.546 65 29.546 65 25.213c0-4.5-.833-8.5-1.667-11.333zm-15 8.666H31.667a3.343 3.343 0 01-3.334-3.333V15.88c0-1 5.167-1.667 11.667-1.667s11.667.667 11.667 1.667v3.333c0 1.833-1.5 3.333-3.334 3.333z"
      />
      <path fill="#D68600" d="M38.333 44.213h3.334V75.88h-3.334V44.213z" />
    </svg>
  );
}

export default Key;
