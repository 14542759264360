import React, { useState } from 'react';
import Partner from './partner';
import data from './partners-data';

const Partners = () => {
  const [show, setShow] = useState(false);
  return (
    <section id="partners" className="pt-5 pb-10 text-center">
      <h2 className="uppercase mt-10 mb-10">Partnereink</h2>
      <div className="md:hidden">
        {!show ? (
          <div className="lg:hidden text-center">
            {data.slice(0, 2).map(partners => (
              <Partner key={partners.name} partners={partners} />
            ))}
            <button
              type="button"
              onClick={() => setShow(true)}
              className="text-white text-center py-4 px-10 bg-primary hover:bg-orange-600 hover:shadow-xl transition duration-200 ease-in-out font-bold  rounded-full inline-block m-4 tracking-wider w-64 lg:ml-0 mx-auto transform hover:-translate-y-1"
            >
              Mutasd mindet
            </button>
          </div>
        ) : (
          <div className="container flex flex-wrap justify-center">
            {data.map(partners => (
              <Partner key={partners.name} partners={partners} />
            ))}
            <button
              type="button"
              onClick={() => setShow(false)}
              className="lg:hidden text-white text-center py-4 px-10 bg-primary hover:bg-orange-600 hover:shadow-xl transition duration-200 ease-in-out font-bold  rounded-full inline-block m-4 tracking-wider w-64 lg:ml-0 mx-auto transform hover:-translate-y-1"
            >
              Mutas kevesebbet
            </button>
          </div>
        )}
      </div>
      <div className="hidden md:block">
        <div className="container flex flex-wrap justify-center">
          {data.map(partners => (
            <Partner key={partners.name} partners={partners} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
