import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Camping from '../icons/camping';
import Key from '../icons/key';

const About = () => {
  const images = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { regex: "/method/tools.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      group: file(relativePath: { regex: "/company/company-team2.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      map: file(relativePath: { regex: "/about/about_map.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      winners: file(relativePath: { regex: "/about/about_team.jpg/" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 414) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="container mt-20 mb-20">
      <h2 className="uppercase text-center mb-16">Mivel foglalkozunk</h2>
      <div className="w-full lg:flex relative mb-20">
        <div className="mt-20">
          <div className="max-w-md mx-auto pl-5 pb-5 pr-5 xl:mr-24 xl:ml-16">
            <p className="mb-10 text-base text-left leading-loose px-1">
              A Fortély-sátor Alapítvány egy több éve működő társadalmi
              vállalkozás, mellyel <b>hátrányos helyzetű</b>, elsősorban 10 és
              18 év közötti gyerekek és{' '}
              <b>fiatalok társadalmi beilleszkedését szeretnénk elősegíteni</b>{' '}
              motiválás és társadalmi érzékenyítés útján.{' '}
              <b>Módszerünk a szabadulószoba</b>, ezt a népszerű csapatjátékot
              alakítottuk pedagógiai eszközzé és tettük teljesen mobillá, így
              elérhető bárhol az országban.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 overflow-hidden">
          <div className="relative max-w-md mx-auto lg:mr-0">
            <div className="absolute z-10 bottom-0 left-0 -ml-40">
              <Camping />
            </div>
            <Img fluid={images.girl.childImageSharp.fluid} alt="Play" />
          </div>
          <div className="max-w-md mx-auto relative mt-10">
            <div className="absolute z-10 top-0 right-0 -mr-32">
              <Key />
            </div>
            <Img fluid={images.group.childImageSharp.fluid} alt="Play" />
          </div>
        </div>
      </div>
      <div className="w-full lg:flex flex-row-reverse relative mb-20">
        <div className="mt-20">
          <div className="max-w-md mx-auto pl-5 pb-5 pr-5 xl:mr-24 xl:ml-16">
            <p className="mb-10 text-base text-left leading-loose px-1">
              Az intenzív játék során megerősítjük a fiatalok csoportmunkával,
              logikai feladatokkal, problémamegoldó képességgel kapcsolatos
              pozitív élményeit, és <b>sikerélményt adunk nekik</b>.
              Kitelepüléseink során iskolák, tanodák és egyéb fiatalokkal
              foglalkozó szervezetek fejlesztő munkáját egészítjük ki egy-egy
              játékos nap keretein belül.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 overflow-hidden">
          <div className="relative max-w-md mx-auto lg:ml-0">
            <div className="absolute z-10 bottom-0 right-0 -mr-32 -mb-5">
              <img alt="Beszámoló" src="/icons/diploma-1.svg" width="110" />
            </div>
            <Img fluid={images.winners.childImageSharp.fluid} alt="Play" />
          </div>
          <div className="max-w-md mx-auto relative mt-10">
            <div className="absolute z-10 bottom-0 left-0 -ml-32 mb-3">
              <img alt="Beszámoló" src="/icons/search_stand.svg" width="120" />
            </div>
            <Img fluid={images.map.childImageSharp.fluid} alt="Play" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
