import React from 'react';
import { Link } from 'gatsby';

const BlogCard = ({ to, key, src, title, lead, date }) => {
  return (
    <div className="lg:w-1/2 ">
      <Link
        to={to}
        key={key}
        className="flex flex-col md:flex-col bg-white border rounded-lg m-5 cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl p-6 duration-200"
      >
        <div className="w-full md:w-full text-left mr-5">
          <h3 className="mb-2">{title}</h3>
          <p className="text-sm italic mb-4">{date}</p>
          <p>{lead}</p>
        </div>
        <div className="w-full md:w-fullflex items-center">
          <img
            className="w-64 md:w-full mt-5 rounded-lg"
            src={src}
            alt={title}
          />
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
