import clsx from 'clsx';
import React from 'react';
import Face from '../icons/face';
import Insta from '../icons/insta';
import Linked from '../icons/linked';
import { baseClasses, variants } from '../layout/button';

// import Diplom from '../icons/diplom';

const Contact = () => {
  return (
    <div className="bg-bgfirst pt-10 mt-20">
      <h2 className="text-center mb-10 uppercase">ELÉRHETŐSÉGEINK</h2>
      <div className="container">
        <div className="flex flex-col md:flex-row justify-center pb-20">
          <div className="md:w-1/2 m-5 flex flex-col items-center p-4">
            <div>
              {/* <p className="mt-5">
                <b>Tel:</b> +36 20 207 35 38
              </p> */}
              <p className="mt-5">
                <b>Székhely:</b> 1096 Budapest, Sobieski János utca 36.
                alagsor/1.
              </p>
              <p>
                <b>Levelezési cím:</b> Appy Centrum, 1066, Budapest, Nyugati tér
                1. VI. emelet.
              </p>
              <p>
                <b>Email:</b> hello@fortelysator.hu
              </p>

              <div className="md:flex">
                <div className="flex flex-col">
                  <h3 className="mt-10">Dokumentumok</h3>
                  {/* <a
                    href="/AlapitoOkirat.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Okírat"
                      src="/icons/diploma.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    Alapító okirat
                  </a> */}

                  <a
                    href="/FsA_Adatvédelmi_nyilatkozat.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Adatvédelem"
                      src="/icons/library.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    Adatvédelmi tájékoztató
                  </a>
                  <a
                    href="/Adomanyozas.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Adományozási feltételek"
                      src="/icons/key.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    Adományozási feltételek
                  </a>
                </div>
                <div className="lg:ml-5">
                  <h3 className="mt-10">Közösségi</h3>
                  <div className="flex mt-5">
                    <a
                      href="https://www.facebook.com/fortelysator/"
                      target="_blank"
                      rel="noreferrer"
                      className="mr-3"
                    >
                      <Face />
                    </a>
                    <a
                      href="https://www.instagram.com/fortelysator/"
                      target="_blank"
                      rel="noreferrer"
                      className="mr-3"
                    >
                      <Insta />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/fort%C3%A9ly-s%C3%A1tor-alap%C3%ADtv%C3%A1ny/ "
                      target="_blank"
                      rel="noreferrer"
                      className="mr-3"
                    >
                      <Linked />
                    </a>
                  </div>
                  {/* <div className="mt-5">
                    <a
                      href="https://fortelysator.us1.list-manage.com/subscribe?u=3abf23d727b1447f8041a2a7f&id=c29c6130f8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pt-4 hover:text-orange-500"
                    >
                      <img
                        alt="Adományozási feltételek"
                        src="/icons/speech.svg"
                        width="40 mr-5"
                        className="inline"
                      />{' '}
                      Feliratkozás hírlevélre
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="">
                <h3 className="mt-10">Beszámolók</h3>
                <div className="flex">
                  <a
                    href="/Beszamolo.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Beszámoló"
                      src="/icons/statistics.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    2018
                  </a>
                </div>
                <div className="flex">
                  <a
                    href="/Beszamolo_2019.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Beszámoló"
                      src="/icons/statistics.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    2019
                  </a>
                </div>
                <div className="flex">
                  <a
                    href="/2020_beszamolo_FS.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-4 hover:text-orange-500"
                  >
                    <img
                      alt="Beszámoló"
                      src="/icons/statistics.svg"
                      width="40 mr-5"
                      className="inline"
                    />{' '}
                    2020
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 m-5 mt-12 xl:mx-20">
            <form
              name="contact"
              action="https://formspree.io/f/mrgoaerr"
              method="POST"
              className="w-full mx-auto bg-white px-2 lg:px-16 pt-10 pb-10  rounded-lg md:p-5"
            >
              <h4 className="text-2xl italic font-medium mt-5">
                Lépj velünk kapcsolatba!
              </h4>
              <input
                type="hidden"
                name="_next"
                value="https://www.fortelysator.hu/"
              />
              <div className="flex flex-wrap -mx-3 mb-6 mt-8">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p className="text-sm font-semibold mb-2">Név</p>
                  <input
                    className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                    placeholder="Gipsz Jakab"
                    id="name"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p className="text-sm font-semibold mb-2">Email</p>
                  <input
                    className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                    placeholder="jakab@gmail.com"
                    id="email"
                    type="text"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 md:mb-0">
                  <p className="text-sm font-semibold mb-2">Tárgy</p>
                  <input
                    className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                    placeholder="Csapatépítő"
                    id="object"
                    type="text"
                    name="object"
                    required
                  />
                </div>
                <div className="w-full px-3 mb-6 md:mb-0 mt-5">
                  <p className="text-sm font-semibold mb-2">Üzenet</p>
                  <textarea
                    className="border border-gray-500 rounded-md p-2 w-full focus:outline-none focus:border-2 focus:border-primary focus:border-transparent"
                    placeholder="Ide írd az üzeneted"
                    id="message"
                    type="text"
                    name="message"
                    rows="8"
                    required
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className={clsx(baseClasses, variants.primary)}
                >
                  Küldés
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
