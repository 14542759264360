import React from 'react';
import CtaCard from '../layout/cta-card';

const MethodCta = () => {
  return (
    <div className="container md:flex md:flex-row py-20">
      <CtaCard
        title="Célcsoport esetén"
        color="bgfirst"
        img="person"
        description="A játék egy csapatnak 45 percig tart és utána közösen beszélgetünk az élményekről. Természetesen igény esetén lehet rövidebb is a játék. Fontos, hogy a játék helyszíne mellett legyen egy külön szoba, ahol tudunk beszélgetni."
        buttonText="TOVÁBB A CÉLCSOPORTRA"
        href="/celcsoportnak"
      />
      <CtaCard
        title="Vállalat esetén"
        color="bgsecond"
        img="building"
        description="Játékaink 15 és 45 perc közöttiek, a megrendelő igényeire szabjuk a játékmenetet is. Ha nyitott térben kerül megrendezésre egy esemény, paravánokból építünk egy 20-25 négyzetméteres szobát, amiből kiszabadulhatnak a résztvevők."
        buttonText="TOVÁBB A VÁLLALATOKRA"
        href="/vallalatoknak"
      />
    </div>
  );
};

export default MethodCta;
