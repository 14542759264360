export default [
  {
    img: 'services',
    width: '100',
    description: 'Logika'
  },
  {
    img: 'handshake',
    width: '100',
    description: 'Kooperáció'
  },
  {
    img: 'chess',
    width: '100',
    description: 'Stratégia'
  },
  {
    img: 'comments',
    width: '100',
    description: 'Kommunikáció'
  }
];
