export default [
  {
    img: 'donate',
    width: '100',
    description: 'Adományozz'
  },
  {
    img: 'briefcase',
    width: '100',
    description: 'Támogass szakértelemmel'
  },
  {
    img: 'debt',
    width: '100',
    description: 'Legyél önkéntes'
  },
  {
    img: 'reading',
    width: '100',
    description: 'Mesélj rólunk'
  }
];
