import React from 'react';
import Button from './button';

const CtaCard = ({
  color,
  title,
  img,
  width,
  description,
  buttonText,
  href,
  description2
}) => {
  return (
    <div
      className={`bg-${color} md:rounded-xl max-w-lg md:m-5 p-5 pt-10 xl:p-10 xl:pt-16`}
    >
      <div className="flex flex-row">
        <h4 className="text-2xl italic font-medium">{title}</h4>
        <img
          alt={img}
          src={`icons/${img}.svg`}
          width={width}
          className="-mt-3 pl-2"
        />
      </div>
      <p className="mt-3 max-w-lg">{description}</p>
      <p className="mt-3 max-w-lg">{description2}</p>
      <Button to={href} variant="outlined" className="mt-8">
        {buttonText}
      </Button>
    </div>
  );
};

export default CtaCard;
