import React from 'react';

function Insta() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 200 200"
    >
      <path
        fill="#000"
        d="M132 100a32 32 0 11-32-32 32.038 32.038 0 0132 32zm68-44v88a56.06 56.06 0 01-16.421 39.579A56.06 56.06 0 01144 200H56a56.062 56.062 0 01-56-56V56A56.064 56.064 0 0156 0h88a56.063 56.063 0 0156 56zm-52 44a48.002 48.002 0 00-29.631-44.346A48 48 0 10100 148a48.05 48.05 0 0033.925-14.075A48.05 48.05 0 00148 100zm16-52a12.004 12.004 0 00-7.408-11.087A12 12 0 10164 48z"
      />
    </svg>
  );
}

export default Insta;
