import React from 'react';

const References = () => {
  return (
    <div className="bg-bgsecond">
      <h2 className="uppercase text-center pt-20 mb-16">Rólunk mondták</h2>
      <div className="container md:flex justify-center pb-20">
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/quote-green.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-center text-sm px-8 italic xl:h-40">
            Nekem nagyon tetszett, hogy ilyen leleményesen készítenek mozgatható
            szabadulószobát. Külön tetszett, hogy a játékidő leteltével
            beszélgettünk az élményeinkről, így nem csak jól szórakoztunk, hanem
            tanultunk is tőlük és egymástól is.
          </p>
          <div className="mx-auto text-center mt-12 mb-10">
            <h3 className="text-lg text-green-700">Viki</h3>
            <p className="text-sm italic">egyetemista</p>
          </div>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/quote-green.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-center text-sm px-8 italic xl:h-40">
            Ha szeretnéd játékos módon elsajátítani a környezettudatosság
            alappilléreit, akkor a Fortély-sátor környezettudatos
            szabadulószobájában a helyed. Vajon mennyivel gazdagabban jutsz ki?
          </p>
          <div className="mx-auto text-center mt-12 mb-10">
            <h3 className="text-lg text-green-700">Berni</h3>
            <p className="text-sm italic">egyetemista</p>
          </div>
        </div>
        <div className="m-5 md:w-1/4 bg-white rounded-lg">
          <img
            alt="Idézet"
            src="/icons/quote-green.svg"
            width="96"
            className="mx-auto mt-8 mb-8"
          />
          <p className="text-center text-sm px-8 italic xl:h-40">
            A Fortély-sátorral körülbelül 3 éve találkoztam először. A
            gyomaendrődi gimnáziumban játszottam az egyik szabadulószobájukkal,
            ahonnan sikeresen kijutottam. Nagyon megtetszett és azóta hálás
            vagyok, hogy egyetemistaként náluk önkénteskedhetem. Az Alapítvány
            megváltoztatta az életemet.
          </p>
          <div className="mx-auto text-center mt-12 mb-10">
            <h3 className="text-lg text-green-700">Edó</h3>
            <p className="text-sm italic">önkéntes</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
