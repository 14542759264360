import React from 'react';
import Button from '../../layout/button';
import Card from '../../layout/card';
import aidData from './aid-data';

const Aid = () => {
  return (
    <div className="bg-bgfirst py-5 xl:py-20">
      <div className="container mt-20 mb-20">
        <h2 className="uppercase text-center mb-16">
          Hogyan tudsz minket támogatni?
        </h2>
        <div className="md:flex">
          <div className="md:w-1/2 p-5 md:mt-20 lg:mt-10">
            <p className="max-w-lg lg:px-20 mx-auto">
              Számos lehetőséged van a munkánkat támogatni, fontosnak tartjuk,
              hogy sokan megtalálják a lehetőséget, amivel jót tehetnek rajtunk
              keresztül. Minden egyes for-profit megrendelés után, legalább egy
              csoporthoz el tudunk menni, így ha a{' '}
              <span className="font-bold">
                cégednél játszotok a szabadulószobánkkal
              </span>
              , akár 35-40 fiatal játékát is garantálhatjátok.
            </p>
            <p className="max-w-lg lg:px-20 mt-5 mx-auto mb-10">
              Támogathatsz minket a{' '}
              <span className="font-bold">szakértelmeddel</span>, kiegészítve
              négyünk munkáját{' '}
              <span className="font-bold">lehetsz önkéntes</span> is, aki
              kitelepülni megy a fiatalokhoz.{' '}
              <span className="font-bold">Anyagi támogatást</span> is fogadunk,
              amit újabb játékok fejlesztésére és a működésünkre fordítjuk.
            </p>
            <div className="text-center">
              <Button to="/tamogatas">Támogass minket!</Button>
            </div>
          </div>
          <div className="md:w-1/2 lg:flex lg:flex-row justify-center">
            <div className="flex flex-col lg:mt-20">
              {aidData.slice(0, 2).map(content => (
                <Card key={content.description} content={content} />
              ))}
            </div>
            <div className="flex flex-col">
              {aidData.slice(2, 4).map(content => (
                <Card key={content.description} content={content} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aid;
